import * as monaco from 'monaco-editor';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import cssWorker from 'monaco-editor/esm/vs/language/css/css.worker?worker';
import htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';

import theme from './theme';

self.MonacoEnvironment = {
	getWorker(_, label) {
		if (label === 'json') {
			return new jsonWorker();
		}
		if (label === 'css' || label === 'scss' || label === 'less') {
			return new cssWorker();
		}
		if (label === 'html' || label === 'handlebars' || label === 'razor') {
			return new htmlWorker();
		}
		if (label === 'typescript' || label === 'javascript') {
			return new tsWorker();
		}
		return new editorWorker();
	},
};

monaco.languages.typescript.typescriptDefaults.setEagerModelSync(true);

export class MonacoEditor {
	private static _instance: MonacoEditor;
	editor: monaco.editor.IStandaloneCodeEditor | null = null;
	constructor() {
		if (MonacoEditor._instance) {
			return MonacoEditor._instance;
		}

		MonacoEditor._instance = this;
	}

	configureEditor(element, language, content) {
		monaco.editor.defineTheme('darkTheme', { ...theme, base: 'vs-dark' });
		monaco.editor.setTheme('darkTheme');

		if (!element.hasChildNodes()) {
			this.editor = monaco.editor.create(element, {
				language,
				value: content,
				minimap: { enabled: false },
				fontSize: 16,
				fontFamily: 'monospace',
				colorDecorators: true,
				bracketPairColorization: { enabled: true },
				tabSize: 2,
				// take up full available size
				overviewRulerLanes: 0,
				overviewRulerBorder: false,
				hideCursorInOverviewRuler: true,
				scrollbar: {
					vertical: 'hidden',
				},
			});
		}
	}

	getEditor() {
		return this.editor;
	}
}
