export default {
	base: 'vs-dark',
	inherit: true,
	rules: [
		{
			background: '002451',
			token: '',
		},
		{
			foreground: '7285b7',
			token: 'comment',
		},
		{
			foreground: 'ffffff',
			token: 'keyword.operator.class',
		},
		{
			foreground: 'ffffff',
			token: 'keyword.operator',
		},
		{
			foreground: 'ffffff',
			token: 'constant.other',
		},
		{
			foreground: 'ffffff',
			token: 'source.php.embedded.line',
		},
		{
			foreground: 'ff9da4',
			token: 'variable',
		},
		{
			foreground: 'ff9da4',
			token: 'support.other.variable',
		},
		{
			foreground: 'ff9da4',
			token: 'string.other.link',
		},
		{
			foreground: 'ff9da4',
			token: 'string.regexp',
		},
		{
			foreground: 'ff9da4',
			token: 'entity.name.tag',
		},
		{
			foreground: 'ff9da4',
			token: 'entity.other.attribute-name',
		},
		{
			foreground: 'ff9da4',
			token: 'meta.tag',
		},
		{
			foreground: 'ff9da4',
			token: 'declaration.tag',
		},
		{
			foreground: 'ff9da4',
			token: 'markup.deleted.git_gutter',
		},
		{
			foreground: 'ffc58f',
			token: 'constant.numeric',
		},
		{
			foreground: 'ffc58f',
			token: 'constant.language',
		},
		{
			foreground: 'ffc58f',
			token: 'support.constant',
		},
		{
			foreground: 'ffc58f',
			token: 'constant.character',
		},
		{
			foreground: 'ffc58f',
			token: 'variable.parameter',
		},
		{
			foreground: 'ffc58f',
			token: 'punctuation.section.embedded',
		},
		{
			foreground: 'ffc58f',
			token: 'keyword.other.unit',
		},
		{
			foreground: 'ffeead',
			token: 'entity.name.class',
		},
		{
			foreground: 'ffeead',
			token: 'entity.name.type.class',
		},
		{
			foreground: 'ffeead',
			token: 'support.type',
		},
		{
			foreground: 'ffeead',
			token: 'support.class',
		},
		{
			foreground: 'd1f1a9',
			token: 'string',
		},
		{
			foreground: 'd1f1a9',
			token: 'constant.other.symbol',
		},
		{
			foreground: 'd1f1a9',
			token: 'entity.other.inherited-class',
		},
		{
			foreground: 'd1f1a9',
			token: 'markup.heading',
		},
		{
			foreground: 'd1f1a9',
			token: 'markup.inserted.git_gutter',
		},
		{
			foreground: '99ffff',
			token: 'keyword.operator',
		},
		{
			foreground: '99ffff',
			token: 'constant.other.color',
		},
		{
			foreground: 'bbdaff',
			token: 'entity.name.function',
		},
		{
			foreground: 'bbdaff',
			token: 'meta.function-call',
		},
		{
			foreground: 'bbdaff',
			token: 'support.function',
		},
		{
			foreground: 'bbdaff',
			token: 'keyword.other.special-method',
		},
		{
			foreground: 'bbdaff',
			token: 'meta.block-level',
		},
		{
			foreground: 'bbdaff',
			token: 'markup.changed.git_gutter',
		},
		{
			foreground: 'ebbbff',
			token: 'keyword',
		},
		{
			foreground: 'ebbbff',
			token: 'storage',
		},
		{
			foreground: 'ebbbff',
			token: 'storage.type',
		},
		{
			foreground: 'ebbbff',
			token: 'entity.name.tag.css',
		},
		{
			foreground: 'ffffff',
			background: 'f99da5',
			token: 'invalid',
		},
		{
			foreground: 'ffffff',
			background: 'bbdafe',
			token: 'meta.separator',
		},
		{
			foreground: 'ffffff',
			background: 'ebbbff',
			token: 'invalid.deprecated',
		},
		{
			foreground: 'ffffff',
			token: 'markup.inserted.diff',
		},
		{
			foreground: 'ffffff',
			token: 'markup.deleted.diff',
		},
		{
			foreground: 'ffffff',
			token: 'meta.diff.header.to-file',
		},
		{
			foreground: 'ffffff',
			token: 'meta.diff.header.from-file',
		},
		{
			foreground: '718c00',
			token: 'markup.inserted.diff',
		},
		{
			foreground: '718c00',
			token: 'meta.diff.header.to-file',
		},
		{
			foreground: 'c82829',
			token: 'markup.deleted.diff',
		},
		{
			foreground: 'c82829',
			token: 'meta.diff.header.from-file',
		},
		{
			foreground: 'ffffff',
			background: '4271ae',
			token: 'meta.diff.header.from-file',
		},
		{
			foreground: 'ffffff',
			background: '4271ae',
			token: 'meta.diff.header.to-file',
		},
		{
			foreground: '3e999f',
			fontStyle: 'italic',
			token: 'meta.diff.range',
		},
	],
	colors: {
		'editor.foreground': '#FFFFFF',
		'editor.background': '#111827',
		'editor.lineHighlightBackground': '#111827',
		'editor.lineHighlightBorder': '#111827',
		'editor.selectionBackground': '#0F2C60',
		'editorLineNumber.foreground': '#9CA3AF',
		'editorLineNumber.activeForeground': '#FFFFFF',
		'editorCursor.foreground': '#FFFFFF',
		'editorWhitespace.foreground': '#CCCCCC1A',
	},
};
